import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import '../components/RealEstatePage.css';
import './CreateEditListing.js';

const RealEstatePage = () => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [selectedListing, setSelectedListing] = useState(null);
    const [listings, setListings] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);

    useEffect(() => {
        axios.get('https://amywagnerdesigns.com/api/real-estate-listings')
            .then(response => {
                const updatedListings = response.data.map(listing => {
                    listing.images = listing.images.map(image => `https://amywagnerdesigns.com/uploads/${image}`);
                    return listing;
                });

                setListings(updatedListings);
                if (updatedListings.length > 0) {
                    setSelectedListing(updatedListings[0]);
                }
            })
            .catch(error => console.error("Error fetching listings:", error));
    }, []);

    const openImageModal = (index) => {
        setModalImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleNextImage = () => {
        if (selectedListing) {
            setModalImageIndex((prevIndex) =>
                (prevIndex + 1) % selectedListing.images.length
            );
        }
    };

    const handlePrevImage = () => {
        if (selectedListing) {
            setModalImageIndex((prevIndex) =>
                (prevIndex - 1 + selectedListing.images.length) %
                selectedListing.images.length
            );
        }
    };

    const handleCreateListing = () => {
        navigate('/real-estate/new');
    };

    const handleEditListing = () => {
        if (selectedListing) {
            navigate(`/real-estate/edit/${selectedListing.id}`);
        }
    };


    const handleDeleteListing = async () => {
        if (!selectedListing) return;

        try {
            await axios.delete(`https://amywagnerdesigns.com/api/real-estate-listings/${selectedListing.id}`);
            const updatedListings = listings.filter(listing => listing.id !== selectedListing.id);
            setListings(updatedListings);
            setSelectedListing(updatedListings.length > 0 ? updatedListings[0] : null);
        } catch (error) {
            console.error("Error deleting listing:", error);
        }
    };

    const ListingThumbnail = ({ listing, onSelect, isSelected }) => {
        const imageUrl = listing.images?.[0] || 'https://amywagnerdesigns.com/uploads/Gant01.jpeg';
        return (
            <div className={`listing-thumbnail ${isSelected ? 'selected' : ''}`} onClick={() => onSelect(listing)}>
                <img src={imageUrl} alt="" />
                <div className={`status-banner ${listing.status.replace(" ", "-").toLowerCase()}`}>
                    {listing.status.replace("-", " ")}
                </div>
                <div className="thumbnail-info">
                    <h3>${listing.price}</h3>
                    <p>
                        {listing.address}<br />
                        {listing.city}, {listing.state} {listing.zip}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="real-estate-page">
                <div className="real-estate-banner">Real Estate Listings</div>

                {selectedListing && (
                    <div className="selected-listing">
                        <div className={`selected-status ${selectedListing.status.replace(" ", "-").toLowerCase()}`}>
                            {selectedListing.status.replace("-", " ")}
                        </div>

                        <div className="listing-info-row">
                            <div className="listing-info-item">
                                <strong>Price:</strong> ${selectedListing.price}
                            </div>
                            <div className="listing-info-item">
                                <strong>MLS:</strong> {selectedListing.mls}
                            </div>
                            <div className="listing-info-item">
                                <strong>Address:</strong> {selectedListing.address}, {selectedListing.city}, {selectedListing.state} {selectedListing.zip}
                            </div>
                            <div className="listing-info-item">
                                <strong>Beds:</strong> {selectedListing.beds}, <strong>Baths:</strong> {selectedListing.baths}
                            </div>
                            <div className="request-showing-container">
                                <Link to="/contact#contact-form" className="request-showing-btn">
                                    Request a showing!
                                </Link>
                            </div>
                        </div>

                        <div className="listing-images">
                            <img
                                src={selectedListing.images[0]}
                                alt="Featured"
                                className="featured-image"
                                onClick={() => openImageModal(0)}
                            />
                        </div>

                        {/* Description Section */}
                        <div className="listing-details">
                            <strong>Description:</strong>
                            <p>{selectedListing.description}</p>
                        </div>

                        <div className="thumbnail-gallery">
                            {selectedListing.images.map((image, index) => (
                                <div key={index} className="thumbnail-wrapper">
                                    <img
                                        src={image}
                                        alt=""
                                        className="thumbnail-image"
                                        onClick={() => openImageModal(index)}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Admin Controls */}
                        {isLoggedIn && (
                            <div className="listing-actions">
                                <button onClick={handleCreateListing} className="create-listing-btn">
                                    Create New Listing
                                </button>
                                {selectedListing && (
                                    <>
                                        <button onClick={handleEditListing} className="edit-listing-btn">
                                            Edit Listing
                                        </button>
                                        <button onClick={handleDeleteListing} className="delete-listing-btn">
                                            Delete Listing
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {/* Active Listings */}
                <div className="listing-thumbnails">
                    {listings.filter(listing => listing.status !== 'sold').map((listing) => (
                        <ListingThumbnail
                            key={listing.id}
                            listing={listing}
                            isSelected={listing === selectedListing}
                            onSelect={setSelectedListing}
                        />
                    ))}
                </div>

                {/* Sold Listings */}
                {listings.some(listing => listing.status === 'sold') && (
                    <div className="sold-listings">
                        <h2>Sold Properties</h2>
                        <div className="listing-thumbnails">
                            {listings.filter(listing => listing.status === 'sold').map((listing) => (
                                <ListingThumbnail
                                    key={listing.id}
                                    listing={listing}
                                    isSelected={listing === selectedListing}
                                    onSelect={setSelectedListing}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Image Modal */}
                {isModalOpen && selectedListing && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <img
                                src={selectedListing.images[modalImageIndex]}
                                alt="Large view"
                            />
                            <div className="modal-controls">
                                <button onClick={handlePrevImage}>&#9665; Prev</button>
                                <button onClick={handleNextImage}>Next &#9655;</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RealEstatePage;

