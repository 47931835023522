import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import '../components/FullScreenImagePage.css';

const FullScreenImagePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { index } = useParams();
    const images = location.state?.images || [];

    const [currentIndex, setCurrentIndex] = useState(Number(index));
    const touchStartX = useRef(0);
    const mouseStartX = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                nextImage();
            } else if (event.key === 'ArrowLeft') {
                prevImage();
            } else if (event.key === 'Escape') {
                navigate(-1); // Go back
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [currentIndex]);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // Handle touch events for swipe navigation (Mobile)
    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        const deltaX = touchEndX - touchStartX.current;

        if (deltaX < -50) {
            nextImage(); // Swipe left → next image
        } else if (deltaX > 50) {
            prevImage(); // Swipe right → previous image
        }
    };

    // Handle mouse drag for desktop swiping
    const handleMouseDown = (e) => {
        mouseStartX.current = e.clientX;
    };

    const handleMouseUp = (e) => {
        if (mouseStartX.current === null) return;

        const deltaX = e.clientX - mouseStartX.current;

        if (deltaX < -50) {
            nextImage(); // Drag left → next image
        } else if (deltaX > 50) {
            prevImage(); // Drag right → previous image
        }

        mouseStartX.current = null; // Reset
    };

    return (
        <div
            className="fullscreen-container"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <button className="close-button" onClick={() => navigate(-1)}>X</button>
            <button className="arrow left" onClick={prevImage}>❮</button>
            <img src={images[currentIndex]} alt="Full Screen" className="fullscreen-image" />
            <button className="arrow right" onClick={nextImage}>❯</button>
        </div>
    );
};

export default FullScreenImagePage;






