import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'; // Added useNavigate
import GlobalNavbar from './components/GlobalNavbar';
import HomePage from './pages/HomePage';
import ContactUsPage from './pages/ContactUsPage';
import HomeRemodelingPage from './pages/HomeRemodelingPage';
import InteriorDesignPage from './pages/InteriorDesignPage';
import VideoToursPage from './pages/VideoToursPage';
import GalleryPage from './pages/GalleryPage';
import AboutUsPage from './pages/AboutUsPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AdminDashboard from './components/AdminDashboard';
import RealEstatePage from './pages/RealEstatePage';
import ProjectForm from './components/ProjectForm';
import FullScreenImagePage from './pages/FullScreenImagePage';
import CreateEditListing from './pages/CreateEditListing';
import StorePage from './pages/StorePage';
import { AuthProvider, useAuth } from './context/authContext'; // Added useAuth

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth(); // Fixed: useAuth properly imported
  const navigate = useNavigate(); // Fixed: useNavigate properly imported

  if (!isLoggedIn) {
    navigate('/login'); // Redirect manually
    return null; // Prevent rendering protected content
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <GlobalNavbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/realestateservices" element={<RealEstatePage />} />
          <Route path="/real-estate/new" element={<CreateEditListing />} />
          <Route path="/real-estate/edit/:id" element={<CreateEditListing />} />
          <Route path="/homeremodeling" element={<HomeRemodelingPage />} />
          <Route path="/interiordesign" element={<InteriorDesignPage />} />
          <Route path="/videotours" element={<VideoToursPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/create-project" element={<ProjectForm />} />
          <Route path="/store" element={<StorePage />} />
          <Route path="/fullscreen/:index" element={<FullScreenImagePage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;



