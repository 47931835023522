import React, { useState, useEffect } from 'react';
import '../Nav.css';
import logo from '../assets/Amy_LOGO.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ReactComponent as XTwitterLogo } from '../assets/twitterx.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import storefrontImage from '../assets/storefront.png';

function GlobalNavbar() {
  const { isLoggedIn, logout } = useAuth();
  const [hovered, setHovered] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageHoverActive, setImageHoverActive] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  // Custom scroll function to go home, then scroll to #testimonials
  const goToTestimonials = () => {
    // Navigate to Home
    navigate('/');
    // Delay scrolling so the HomePage can render
    setTimeout(() => {
      const testimonialsSection = document.getElementById('testimonials');
      if (testimonialsSection) {
        testimonialsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  useEffect(() => {
    if (hovered) {
      setShowImage(true);
      const timer = setTimeout(() => {
        setImageHoverActive(true);
      }, 5000);

      return () => clearTimeout(timer);
    } else {
      setShowImage(false);
      setImageHoverActive(false);
    }
  }, [hovered]);

  return (
    <div>
      {/* TOP TEAL BAR */}
      <div className="teal-navbar">
        <div className="teal-navbar-left">
          <a href="tel:502-384-3660" className="maroon-link">
            <FontAwesomeIcon icon={faPhone} /> 502-384-3660
          </a>
          <a href="mailto:info.reflectionsofyou@gmail.com" className="maroon-link">
            <FontAwesomeIcon icon={faEnvelope} /> info.reflectionsofyou@gmail.com
          </a>
          <div className="social-links">
            <a href="https://www.facebook.com/reflctionsofyoubyamy?mibextid=ZbWKwL" className="social-link">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <span className="social-link-space"></span>
            <a href="https://twitter.com/amywagnerdesign" className="social-link">
              <XTwitterLogo className="x-icon" />
            </a>
            <span className="social-link-space"></span>
            <a
              href="https://instagram.com/amywagnerdesigns?igshid=MzRlODBiNWFlZA=="
              className="social-link"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>

        {isLoggedIn ? (
          <button onClick={handleLogout}>Logout</button>
        ) : (
          <Link to="/login">My Account</Link>
        )}
      </div>

      {/* MAIN CREAM NAVBAR */}
      <div className="cream-navbar">
        <div className="cream-navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </div>
        <div className="cream-navbar-right">
          {/* HOME */}
          <Link to="/">
            <button className="cream-navbar-link big-link">Home</button>
          </Link>

          {/* OUR SERVICES DROPDOWN */}
          <div className="dropdown">
            <button className="cream-navbar-link big-link dropdown-toggle">Our Services</button>
            <div className="dropdown-menu">
              <Link to="/realEstateServices" className="dropdown-item">
                Real Estate Services
              </Link>
              <Link to="/interiordesign" className="dropdown-item">
                Interior Design
              </Link>
            </div>
          </div>

          {/* ABOUT US DROPDOWN */}
          <div className="dropdown">
            <button className="cream-navbar-link big-link dropdown-toggle">About Us</button>
            <div className="dropdown-menu">
              <Link to="/aboutus" className="dropdown-item">
                About Us
              </Link>

              {/* Testimonials link with same style as other dropdown items */}
              <Link
                to="#"
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  goToTestimonials();
                }}
              >
                Testimonials
              </Link>
            </div>
          </div>

          {/* CONTACT US */}
          <Link to="/contact">
            <button className="cream-navbar-link big-link">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GlobalNavbar;

