import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProjectForm = () => {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [page, setPage] = useState('');
    const [images, setImages] = useState([]);
    const [error, setError] = useState(null); // For error handling
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Reset errors

        const formData = new FormData();
        formData.append('project_name', projectName);
        formData.append('project_description', projectDescription);
        formData.append('page', page);

        // ✅ Append images one by one
        images.forEach(image => {
            formData.append('images', image);
        });

        try {
            await axios.post(
                'https://amywagnerdesigns.com/api/projects',
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    timeout: 180000, // ⏳ 3 min timeout (prevents large file cutoffs)
                }
            );
            navigate(`/${page}`);
        } catch (error) {
            console.error("Error creating project:", error);
            setError(error.response?.data?.message || "An error occurred while creating the project.");
        }
    };


    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);

        const validFiles = selectedFiles.filter(file => file.type.startsWith('image/')); // ✅ Ensure only images
        if (validFiles.length < selectedFiles.length) {
            alert('Only image files are allowed. Some files were not added.');
        }

        setImages(prevImages => [...prevImages, ...validFiles]); // ✅ Append to previous images
    };


    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>
                    Project Name:
                    <input
                        type="text"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    Project Description:
                    <textarea
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    Page:
                    <select
                        value={page}
                        onChange={(e) => setPage(e.target.value)}
                        required
                    >
                        <option value="">Select Page</option>
                        <option value="HomeRemodeling">Home Remodeling</option>
                        <option value="Gallery">Gallery</option>
                        <option value="InteriorDesign">Interior Design</option>
                    </select>
                </label>
            </div>
            <div>
                <label>
                    Upload Images:
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                    />
                </label>
                {images.length > 0 && (
                    <div>
                        <p>Selected Images:</p>
                        <ul>
                            {images.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <button type="submit">Create Project</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
    );
};

export default ProjectForm;





