import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import '../components/DesignPage.css';

const InteriorDesignPage = () => {
    const [projects, setProjects] = useState([]);
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [newImages, setNewImages] = useState({});
    const [expandedProjects, setExpandedProjects] = useState({}); // ✅ Track expanded state per project

    // ✅ Fetch projects from the backend
    const fetchProjects = useCallback(async () => {
        try {
            const response = await axios.get(`https://amywagnerdesigns.com/api/projects/InteriorDesign`, {
                headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' },
                params: { t: new Date().getTime() } // Force fresh data
            });
            console.log("Received projects data:", response.data);
            setProjects(response.data.projects);
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    }, []);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const deleteProject = async (projectId) => {
        try {
            await axios.delete(`https://amywagnerdesigns.com/api/projects/${projectId}`);
            await fetchProjects();
        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };

    const openFullScreenView = (index, images) => {
        navigate(`/fullscreen/${index}`, { state: { images } });
    };

    const handleFileChange = (e, projectId) => {
        const selectedFiles = Array.from(e.target.files);

        setNewImages((prev) => ({
            ...prev,
            [projectId]: prev[projectId] ? [...prev[projectId], ...selectedFiles] : selectedFiles,
        }));
    };

    const handleAddImages = async (projectId) => {
        if (!newImages[projectId] || newImages[projectId].length === 0) {
            alert("Please select images to upload.");
            return;
        }

        const formData = new FormData();
        newImages[projectId].forEach(image => formData.append('images', image));

        try {
            const response = await axios.post(
                `https://amywagnerdesigns.com/api/projects/${projectId}/images`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    timeout: 180000, // ⏳ 3 min timeout (to prevent large upload cutoffs)
                }
            );

            if (response.data.success) {
                alert("Images added successfully!");
                await fetchProjects();
            } else {
                alert("Server error: " + response.data.message);
            }
        } catch (error) {
            console.error("❌ Error uploading images:", error);
            alert("Upload failed: " + error.message);
        }
    };

    const handleDeleteImage = async (projectId, imageUrl) => {
        console.log("🔵 Sending DELETE request with payload:", { projectId, imageUrl });

        try {
            const response = await axios.delete(`https://amywagnerdesigns.com/api/projects/${projectId}/images`, {
                data: { imageUrl },
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.data.success) {
                console.log("✅ Image deleted successfully!");

                setProjects(prevProjects =>
                    prevProjects.map(project =>
                        project.project_id === projectId
                            ? { ...project, images: project.images.filter(img => img !== imageUrl) }
                            : project
                    )
                );

                await fetchProjects();
            }
        } catch (error) {
            console.error("❌ Error deleting image:", error);
            alert("Failed to delete image.");
        }
    };

    const toggleExpand = (projectId) => {
        setExpandedProjects((prev) => ({
            ...prev,
            [projectId]: !prev[projectId] // Toggle expand/collapse
        }));
    };

    return (
        <div className="design-page">
            <div className="design-banner">Interior Design</div>
            {isLoggedIn && (
                <Link to="/create-project" className="design-button">Create New Project</Link>
            )}
            <div className="projects-container">
                {projects.map((project) => {
                    const isExpanded = expandedProjects[project.project_id] || false;
                    const displayedImages = isExpanded ? project.images : project.images.slice(0, 3);

                    return (
                        <div key={project.project_id} className="design-project">
                            <h2>{project.project_name}</h2>
                            <p>{project.project_description}</p>
                            <div className="images-container">
                                {displayedImages.map((imageUrl, index) => (
                                    <div key={index} className="image-wrapper">
                                        <img
                                            src={imageUrl}
                                            alt={project.project_name}
                                            onClick={() => openFullScreenView(index, project.images)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        {isLoggedIn && (
                                            <button className="delete-image-button" onClick={() => handleDeleteImage(project.project_id, imageUrl)}>
                                                X
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {project.images.length > 3 && (
                                <button
                                    className="expand-button"
                                    onClick={() => toggleExpand(project.project_id)}
                                >
                                    {isExpanded ? "Show Less" : "Show More"}
                                </button>
                            )}
                            {isLoggedIn && (
                                <>
                                    <div className="upload-section">
                                        <input type="file" multiple onChange={(e) => handleFileChange(e, project.project_id)} />
                                        <button onClick={() => handleAddImages(project.project_id)}>Add Images</button>
                                    </div>
                                    <button onClick={() => deleteProject(project.project_id)} className="design-button">
                                        Delete Project
                                    </button>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>

            <div className="schedule-section">
                <p>Schedule Your Initial Consult Today!</p>
                <Link to="/contact" style={{ textDecoration: 'none', width: '100%' }}>
                    <button className="schedule-button">Schedule Today!</button>
                </Link>
            </div>
        </div>
    );
};

export default InteriorDesignPage;







