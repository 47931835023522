import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../components/CreateEditListing.css';

const CreateEditListing = () => {
    const { id } = useParams(); // Get listing ID if editing
    const navigate = useNavigate();
    const isEditMode = Boolean(id); // Check if in edit mode

    // Initial State
    const [listing, setListing] = useState({
        address: '',
        city: '',
        state: '',
        zip: '',
        price: '',
        beds: '',
        baths: '',
        sqft: '',
        description: '',
        mls: '',
        listingAgent: '',
        status: 'for sale',
    });

    const [existingImages, setExistingImages] = useState([]); // For already uploaded images
    const [newImages, setNewImages] = useState([]); // For new uploads
    const [loading, setLoading] = useState(false);

    // Fetch existing listing if in edit mode
    useEffect(() => {
        if (isEditMode) {
            axios.get(`https://amywagnerdesigns.com/api/real-estate-listings/${id}`)
                .then(response => {
                    setListing(response.data);
                    setExistingImages(response.data.images || []);
                })
                .catch(error => console.error("Error fetching listing:", error));
        }
    }, [id, isEditMode]);

    // Handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setListing({
            ...listing,
            [name]: value,
        });
    };

    // Handle new image uploads
    const onDrop = useCallback((acceptedFiles) => {
        setNewImages([...newImages, ...acceptedFiles]);
    }, [newImages]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
    });

    // Remove images
    const handleRemoveExistingImage = (index) => {
        setExistingImages(existingImages.filter((_, i) => i !== index));
    };

    const handleRemoveNewImage = (index) => {
        setNewImages(newImages.filter((_, i) => i !== index));
    };

    // Handle Form Submission for Create or Edit
    const handleSaveListing = async () => {
        const formData = new FormData();

        // ✅ Append listing data (except images)
        Object.keys(listing).forEach(key => {
            formData.append(key, listing[key]);
        });

        // ✅ Only append existing images in edit mode
        if (isEditMode && existingImages.length > 0) {
            existingImages.forEach((image) => {
                formData.append('existingImages', image);
            });
        }

        // ✅ Append images if user selected any
        if (newImages.length > 0) {
            newImages.forEach((file) => {
                formData.append('images', file); // Match backend's `images`
            });
        }

        setLoading(true);
        try {
            if (isEditMode) {
                await axios.put(`https://amywagnerdesigns.com/api/real-estate-listings/${id}`, formData);
            } else {
                await axios.post(`https://amywagnerdesigns.com/api/real-estate-listings`, formData);
            }
            navigate('/realestateservices');
        } catch (error) {
            console.error("Error saving listing:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="edit-listing-page">
            <h1>{isEditMode ? "Edit Listing" : "Create New Listing"}</h1>
            <div className="listing-form">
                <input type="text" name="address" placeholder="Address" value={listing.address} onChange={handleInputChange} />
                <input type="text" name="city" placeholder="City" value={listing.city} onChange={handleInputChange} />
                <input type="text" name="state" placeholder="State" value={listing.state} onChange={handleInputChange} />
                <input type="text" name="zip" placeholder="Zip Code" value={listing.zip} onChange={handleInputChange} />
                <input type="text" name="price" placeholder="Price" value={listing.price} onChange={handleInputChange} />
                <input type="text" name="beds" placeholder="Beds" value={listing.beds} onChange={handleInputChange} />
                <input type="text" name="baths" placeholder="Baths" value={listing.baths} onChange={handleInputChange} />
                <input type="text" name="sqft" placeholder="Square Feet" value={listing.sqft} onChange={handleInputChange} />
                <input type="text" name="mls" placeholder="MLS #" value={listing.mls} onChange={handleInputChange} />
                <input type="text" name="listingAgent" placeholder="Listing Agent" value={listing.listingAgent} onChange={handleInputChange} />
                <textarea name="description" placeholder="Description" value={listing.description} onChange={handleInputChange}></textarea>

                <label>Status:</label>
                <select name="status" value={listing.status} onChange={handleInputChange}>
                    <option value="for sale">For Sale</option>
                    <option value="under contract">Under Contract</option>
                    <option value="pending sale">Pending Sale</option>
                    <option value="sold">Sold</option>
                </select>

                {/* Existing Images (Only in Edit Mode) */}
                {isEditMode && existingImages.length > 0 && (
                    <>
                        <h3>Current Images</h3>
                        <div className="image-preview">
                            {existingImages.map((img, index) => (
                                <div key={index} className="image-item">
                                    <img src={img} alt={`Existing ${index}`} />
                                    <button onClick={() => handleRemoveExistingImage(index)}>Remove</button>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                {/* ✅ Allow Image Uploads for Both Create & Edit */}
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Drop images here...</p> : <p>Drag & drop or click to upload</p>}
                </div>

                <h3>New Images</h3>
                <div className="image-preview">
                    {newImages.map((file, index) => (
                        <div key={index} className="image-item">
                            <img src={URL.createObjectURL(file)} alt={`New ${index}`} />
                            <button onClick={() => handleRemoveNewImage(index)}>Remove</button>
                        </div>
                    ))}
                </div>

                <button className="save-listing-btn" onClick={handleSaveListing} disabled={loading}>
                    {loading ? "Saving..." : isEditMode ? "Update Listing" : "Create Listing"}
                </button>
            </div>
        </div>
    );
};

export default CreateEditListing;





